import {Button} from "@material-ui/core";
import {navigate} from "gatsby";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    right: 0,
    top: -theme.spacing(6),
  }
}));

export default function PollButton() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        to="/"
        color="secondary"
        variant="outlined"
        className={classes.button}
        onClick={() => navigate('/')}
      >
        zur Umfrage
      </Button>
    </div>
  );
}
