import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from 'gatsby-theme-material-ui';
import {Breadcrumbs, Grid} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import aprLogo from './apr.png';
import vaunetLogo from './vaunet.svg';
import {PollButton} from "./index";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
    textAlign: 'center',
  },
  footerLine: {
    paddingBottom: theme.spacing(3),
  },
  logo: {
    height: '4rem',
  },
}));

export default function SinglePage({children, title, showPollButton}) {
  const classes = useStyles();

  return (
    <div
      role='application'
      className={classes.root}
      onDragStart={event => {
        event.preventDefault();
      }}
      onDragOver={event => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'none';
      }}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{title ? `${title} - ` : ''}umfrage.sendemeldung.de</title>
      </Helmet>
      <CssBaseline/>
      <Container component="main" className={classes.main} maxWidth="md">
        {showPollButton ? (
          <PollButton/>
        ) : null}
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={title ? "/" : null}>
            umfrage.sendemeldung.de
          </Link>
          {title ? (
            <Typography color="textPrimary">{title}</Typography>
          ) : null}
        </Breadcrumbs>
        <noscript>
          <Alert severity="error">
            <AlertTitle>JavaScript erforderlich</AlertTitle>
            Bitte aktivieren Sie JavaScript, um diese Website zu nutzen.
          </Alert>
        </noscript>
        {children}
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body2" color="textSecondary" className={classes.footerLine}>
            <Link to="/impressum/" color="secondary">
              Impressum
            </Link>{' / '}
            <Link to="/datenschutz/" color="secondary">
              Datenschutzerklärung
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.footerLine}>
            Die Durchführung dieser Umfrage erfolgt im Auftrag von
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs>
              <Link color="inherit" href="https://www.privatfunk.de/" target="_blank">
                <img src={aprLogo} alt="APR" className={classes.logo}/>
                <p>APR &ndash; Arbeitsgemeinschaft privater Rundfunk</p>
              </Link>
            </Grid>
            <Grid item xs>
              <Link color="inherit" href="https://www.vau.net/" target="_blank">
                <img src={vaunetLogo} alt="VAUNET" className={classes.logo}/>
                <p>VAUNET &ndash;  Verband Privater Medien e.V.</p>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  );
}
